const NODE_DEV = 'http://localhost:8080'
// export const NODE_DEV = 'https://nodeserver-dot-uma-development-ar.uc.r.appspot.com'

/* Si existe esta variable de entorno estoy en el server */
export const is_server = process.env.UMA_APIS_SSR_BASE_URL

/**
 * UMA_APIS_SSR_BASE_URL no tiene Public, así que sólo existe en el server, si estas en el server tomas esa, sino tomas la otra. 
 * Esto es para en serverSide pegarle a una url interna
 * Es muy importante que sea así porque sino se rompe, porque desde el server de Next no podes pegarle al Megalith si no es por la url interna,
 * por cuestiones de seguridad, tener cuidado si toca esta variable de entorno, sobretodo tener cuidado porque en beta si va a andar
 * pero en staging y prod no (en beta si se le puede pegar al backend de Megalith desde cualquir url)
 */
const uma_apis_url = process.env.UMA_APIS_SSR_BASE_URL ?? process.env.NEXT_PUBLIC_UMA_APIS_BASE_URL

// node
export const NODE_SERVER =
	process.env.NEXT_PUBLIC_LOCAL === 'true'
		? NODE_DEV
		: process.env.NEXT_PUBLIC_NODE_URL
const url_facePos = 'https://teachablemachine.withgoogle.com/models/-Eupnxuwx'
const ai = `${uma_apis_url}/ai`

export const TRIAGE_UMA = `${uma_apis_url}/ai/autonomous`
export const UMA_BACKEND_URL = `${uma_apis_url}/patient`
export const UMA_BACKEND_APIKEY = process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY

export const node_make_appointment = `${UMA_BACKEND_URL}/appointments/makeAppointment`
export const confirm_guard_appointment = `${UMA_BACKEND_URL}/appointments/guardia/confirm`
export const node_make_appointment_V2 = `${NODE_SERVER}/appointments/makeAppointmentV2`
export const make_specialist_appointment = `${NODE_SERVER}/appointments/makeSpecialistAppointment`
export const make_onsite_appointment = `${NODE_SERVER}/appointments/makeOnsiteAppointment`
export const make_specialist_appointment_v2 = `${NODE_SERVER}/appointments/makeSpecialistAppointmentV2`
export const queue_unsuspend = `${UMA_BACKEND_URL}/appointments/guardia/unsuspend`
export const cancel_guardia_appointment = `${UMA_BACKEND_URL}/appointments/guardia/cancel`
export const save_complaint = `${UMA_BACKEND_URL}/appointments/guardia/complain`
export const feedback = `${UMA_BACKEND_URL}/appointments/guardia/feedback`
export const feedbackCSAT = `${UMA_BACKEND_URL}/appointments/guardia/feedbackCSAT`
export const feedback_autonomous = `${UMA_BACKEND_URL}/autonomous/feedback`
export const autonomous_next = `${TRIAGE_UMA}/autonomous_next`
export const node_umacare = `${NODE_SERVER}/umacare`
export const diabetes_research = `${UMA_BACKEND_URL}/research`
export const chat = `${UMA_BACKEND_URL}/chatPatient/save`
export const closeChat = `${NODE_SERVER}/appointments/closeChat`
// pdf
export const prescription_pdf = `${UMA_BACKEND_URL}/pdf/prescription`
export const send_prescription_mail = `${NODE_SERVER}/email/shareQuickPrescription`
export const send_prescription_ws = `${NODE_SERVER}/messaging/whatsapp/SendQuickPrescripion`
export const order_pdf = `${UMA_BACKEND_URL}/pdf/order`
export const constancy_download = `${UMA_BACKEND_URL}/pdf/constancy`
export const resume_download = `${UMA_BACKEND_URL}/pdf/resume`

// Recovery
export const recovery_account = `${UMA_BACKEND_URL}/user/recovery`
export const recovery_final_step = `${UMA_BACKEND_URL}/user/recovery/finalStep`

// Patients
export const patient_check_email = `${NODE_SERVER}/patients/emailexists`
export const patient_logs = `${UMA_BACKEND_URL}/user/logs`
export const create_dependant = `${UMA_BACKEND_URL}/dependant`
export const get_dependant = `${UMA_BACKEND_URL}/dependant`
export const confirm_address = `${NODE_SERVER}/delivery/confirm_address`
export const register_coverage_dependant = `${UMA_BACKEND_URL}/coverages/dependant/register`
export const register_coverage = `${UMA_BACKEND_URL}/coverages/register`
export const update_patient = `${UMA_BACKEND_URL}/user/update`
export const register_patient = `${UMA_BACKEND_URL}/user/registerPatient`
export const update_dependant = `${UMA_BACKEND_URL}/dependant/update`
export const get_primary = `${UMA_BACKEND_URL}/coverages/primary`
export const get_primary_dependant = `${UMA_BACKEND_URL}/coverages/dependant/primary`
export const merge_data_pfa = `${UMA_BACKEND_URL}/coverages/pfaMergeData`
export const check_patient_field = `${UMA_BACKEND_URL}/user/checkField`
export const send_phone_code = `${UMA_BACKEND_URL}/contactValidation/phone/generateCode`
export const validate_phone_code = `${UMA_BACKEND_URL}/contactValidation/phone/validateCode`
export const validate_dni = `${UMA_BACKEND_URL}/user/validateDni`
export const check_validation_dni = `${UMA_BACKEND_URL}/user/checkValidationDni`
export const save_dni_images = `${UMA_BACKEND_URL}/user/saveDniImage`
export const validate_coverage_token = `${UMA_BACKEND_URL}/coverages/coverage_token`
export const get_patient_by_uid = `${UMA_BACKEND_URL}/user/getByUid`
// Appointments
export const get_specialties = `${UMA_BACKEND_URL}/appointments/online/specialties`
export const cancel_specialist_appointment_online = `${UMA_BACKEND_URL}/appointments/online/cancel`
export const delay_cancel_specialist_appointment_online = `${UMA_BACKEND_URL}/appointments/online/delayCancel`
export const cancel_specialist_appointment_onsite = `${UMA_BACKEND_URL}/appointments/onsite/cancel`
export const get_taken_appointments = `${UMA_BACKEND_URL}/user/getTakenAppointments`
export const get_active_specialist_appointment_online = `${UMA_BACKEND_URL}/appointments/online/getActive`
export const send_wpp_notification = `${UMA_BACKEND_URL}/notifications/whatsapp`
export const get_delay_guardia = `${UMA_BACKEND_URL}/statistics/guardia/delay`
export const check_guardia_timeout = `${UMA_BACKEND_URL}/appointments/guardia/checkTimeout`
export const get_guardia_timeout = `${UMA_BACKEND_URL}/appointments/guardia/timeout`

// Payments
export const guardia_checkout = `${UMA_BACKEND_URL}/payment/guardia/checkout`
export const mp_payment_url_refunds_especialista = `${UMA_BACKEND_URL}/payment/refund/specialist`
export const mp_payment_url_refunds_guardia = `${UMA_BACKEND_URL}/payment/refund/guardia`

export const mp_payment_url_specialist = `${UMA_BACKEND_URL}/payment/specialist/checkout`
/** @deprecated no se utiliza más */
/** @deprecated no se utiliza más */
export const get_prices = `${NODE_SERVER}/appointments/consultorio/prices`

export const refundsMethods = `${UMA_BACKEND_URL}/appointments/online/refunds`

// Subscriptions
export const cancel_subscription = `${NODE_SERVER}/payments/mercadopago/cancelSubscription`
export const process_payment = `${NODE_SERVER}/payments/mercadopago/paySubscription`
export const refund_service = `${NODE_SERVER}/payments/refundService`
export const subscription_discount = `${NODE_SERVER}/payments/mercadopago/subscriptions/price`
export const pending_subscription = `${UMA_BACKEND_URL}/subscriptions/pending`
export const activate_subscription = `${UMA_BACKEND_URL}/subscriptions/activate`
export const mercadopago_checkout =
	'https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id='

// Models
export const url_facePos_model = `${url_facePos}/model.json`
export const url_facePos_metadata = `${url_facePos}/metadata.json`
export const url_diabetes = `${ai}/diabetes_predictor`
export const url_cardio = `${ai}/cardiac_predictor`

// Cardio - Diabetes
/** @deprecated no se utiliza más */
/** @deprecated no se utiliza más */
export const update_research = `${NODE_SERVER}/research/patient/update/`
/** @deprecated no se utiliza más */
export const update_research_provider = `${NODE_SERVER}/research/provider/update/`

// Feedback deliver
/** @deprecated No se usa más */

// Delivery // no se usa mas
/** @deprecated No se usa más */
export const create_delivery = `${NODE_SERVER}/delivery`
// Biomarkers
/** @deprecated No se usa más */
export const post_biomarkers = `${NODE_SERVER}/events/biomarkers`
// Pillbox
export const pillbox = `${UMA_BACKEND_URL}/pillbox/reminder`
export const delete_pillbox = `${UMA_BACKEND_URL}/pillbox/reminder/delete`
// Repository
export const medical_studies_repo = `${UMA_BACKEND_URL}/medicalStudies`
// ai
export const faq = `${ai}/faq_covid`
export const search_corporate = `${ai}/corporate_searcher`
export const medical_qa = `${ai}/medical_qa`

// Vaccine
// Copay
export const check_copay = `${NODE_SERVER}/padrones/copay`
// Appointments
export const save_online_attached = `${UMA_BACKEND_URL}/appointments/online/saveAttached`
export const save_bag_attached = `${UMA_BACKEND_URL}/appointments/guardia/saveAttached`
export const get_bag_attached = `${UMA_BACKEND_URL}/appointments/guardia/documentReferences`

// Survey
/** @deprecated No se usa más */
export const survey_endpoint = `${NODE_SERVER}/survey`

// Express Appointment
export const express_appointment = `${NODE_SERVER}/expressappointment`

// Monitoring
export const monitoring_hta = `${UMA_BACKEND_URL}/monitoring/hypertension`

// doppler mkt
export const fromdoppler = `${UMA_BACKEND_URL}/marketing/doppler`

// chatAtt
export const chatAtt_make_appointment = `${UMA_BACKEND_URL}/chatAtt/makeAppointment`
export const chatAtt_make_appointment_dependant = `${UMA_BACKEND_URL}/chatAtt/makeAppointment/dependant`
export const chatAtt_chat = `${UMA_BACKEND_URL}/chatPatient/saveChatAtt`
export const chatAtt_checkout = `${UMA_BACKEND_URL}/payment/chatAtt/checkout`
export const chatAtt_chat_seen = `${UMA_BACKEND_URL}/chatPatient/viewMessage`
export const chatAtt_cancel_appointment = `${UMA_BACKEND_URL}/chatAtt/cancel`
export const chatAtt_get_past_conversation = `${UMA_BACKEND_URL}/chatPatient/messages`
export const save_chatAtt_attached = `${UMA_BACKEND_URL}/chatPatient/saveAttached`

// quickPrescription
export const quickPrescription_make_appointment = `${UMA_BACKEND_URL}/quickPrescription/makeAppointment`
export const quickPrescription_make_appointment_dependant = `${UMA_BACKEND_URL}/quickPrescription/makeAppointment/dependant`

// Specialist
export const get_active_specialists = `${UMA_BACKEND_URL}/healthProviders/onlineSpecialist/getAllSpecialists`
export const get_specialist = `${UMA_BACKEND_URL}/healthProviders/specialtyProviders`
export const get_provider_shift = `${UMA_BACKEND_URL}/healthProviders/shift`

// MedicalRecord
export const get_last_mr = `${UMA_BACKEND_URL}/user/medicalRecords/last`
export const get_mr_byId = `${UMA_BACKEND_URL}/user/medicalRecords/byId`

export const get_last_mr_dependant = `${UMA_BACKEND_URL}/dependant/medicalRecords/last`
export const get_mr_byId_server = `${UMA_BACKEND_URL}/user/medicalRecords/byId`
// Nom035
export const getNomFormLinks = (email: string, formId: string): string =>
	`${NODE_SERVER}/web/getNomForm/${email}/${formId}`
export const getNomForms = (email: string): string =>
	`${NODE_SERVER}/web/getNomForms/${email}`
export const postRequiredPasswordChange = (uid: string): string =>
	`${UMA_BACKEND_URL}/nom035/user/uid/${uid}/password`

// Dengue Chatbot
export const url_dengue_chatbot = `${UMA_BACKEND_URL}/dengueChatbot`
export const newDengueChat = `${url_dengue_chatbot}/chat`
export const sendDengueChatbotMessage = `${url_dengue_chatbot}/sendMessage`

// Support Chatbot
export const url_chatbot = `${UMA_BACKEND_URL}/supportChatbot`
export const newSupportChat = `${url_chatbot}/chat`
export const sendChatbotMessage = `${url_chatbot}/sendMessage`
export const createChatbotTicket = `${url_chatbot}/createZendeskTicket`
export const updateChatbotMessage = `${url_chatbot}/updateMessage`
export const closeChatbot = `${url_chatbot}/closeChat`
export const url_chatHistory = `${url_chatbot}/chatHistory`
export const getChatMessages = `${url_chatbot}/chatMessages`

//Externals
export const internals_auth = `${uma_apis_url}/internal/auth/userCustomToken`

//Farmatodo
export const download_prm = `${NODE_SERVER}/pdf/prm`
/** para verificar si hay lugares de atención en la guardia, en principio sólo para IOMA */
export const verify_slots = `${UMA_BACKEND_URL}/appointments/guardia/verifySlots`
//Notifications
export const notifications = `${UMA_BACKEND_URL}/notifications`

export const notification_id = (notificationId: string) =>
	`${uma_apis_url}/notifications/${notificationId}`
export const notification_read = (notificationId: string) =>
	`${uma_apis_url}/notifications/${notificationId}`

// Invite
export const invite_dependant = `${UMA_BACKEND_URL}/dependant/sendInvitation`

// Coverages
export const get_coverage_active_services = `${UMA_BACKEND_URL}/coverages/activeServices`

// Requests
export const get_last_request = `${UMA_BACKEND_URL}/request/last`
export const validate_ioma = (uid : string) => `${UMA_BACKEND_URL}/coverages/validate/${uid}`
